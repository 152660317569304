"use client"

import { type ChangeEvent } from "react"
import clsx from "clsx"

import type { InferReturn, Nullish, PropsWithClassName } from "~/@types/generics"
import { STOCK_LOW } from "~/lib/shopify/constants"
import { useGetStockByMarket, useQueryStockParams } from "~/hooks/useGetStockByMarket"
import type { TVariant } from "~/components/ui/ProductHeader/_data/types"
import type serializeVariantsSelector from "~/components/ui/ProductHeader/components/ProductDetails/components/ProductVariantsSelector/_data/serializer"
import { VariantSelector } from "~/components/ui/VariantSelector"
import Select from "~/components/abstracts/Select"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { useIsFixedPanelOpen } from "~/managers/AddToCartFixedPanelManager/hooks"
import type { VariantsRowWithVariantSwitchProps } from "~/app/[locale]/products/[slug]/(components)/VariantsRowWithVariantSwitch"

import { sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

type ProductVariantsSelectorProps = Omit<InferReturn<typeof serializeVariantsSelector>, "defaultVariant" | "options"> &
  PropsWithClassName<{
    withMobileSelect?: boolean
    withRouterChange?: boolean
    stockAlertEnabled?: boolean
    activeVariant: Nullish<TVariant>
    onChange?: (index: number | undefined) => void
  }> &
  Pick<VariantsRowWithVariantSwitchProps, "isInAddToCartPopin">

export type VariantSelectorStates = "unavailable" | "stock_alert" | "default"

type VariantButtonProps = {
  variant: ProductVariantsSelectorProps["variants"][number]
  index: number
  activeVariant: Nullish<TVariant>
  stockAlertEnabled?: boolean
  isFixedPanelOpenElement?: boolean
  onChange: ProductVariantsSelectorProps["onChange"]
}
const VariantButton = ({
  variant,
  index,
  activeVariant,
  stockAlertEnabled,
  isFixedPanelOpenElement,
  onChange,
}: VariantButtonProps) => {
  const isActive = variant.id === activeVariant?.id

  const { isStockLow, availableForSale, size, sku } = variant

  const params = useQueryStockParams()
  const { data } = useGetStockByMarket(sku ? [sku] : null, params)

  const processedStock = data?.[0]

  const variantState = (): VariantSelectorStates => {
    if (processedStock !== undefined) {
      if (processedStock === 0) {
        if (stockAlertEnabled) return "stock_alert"
        return "unavailable"
      } else {
        return "default"
      }
    } else {
      if (!availableForSale) {
        if (stockAlertEnabled) return "stock_alert"
        return "unavailable"
      }
      return "default"
    }
  }

  const state = variantState()

  const stockState = () => {
    const shouldDisplayLowStock = state !== "unavailable"
    if (state === "stock_alert") return "default"
    if (!shouldDisplayLowStock) return "default"
    return (processedStock !== undefined && processedStock <= STOCK_LOW) || isStockLow ? "low_stock" : "default"
  }

  const handleOnClick = () => {
    onChange?.(index)
  }

  return (
    <VariantSelector
      className={clsx(css.variantSelector, isFixedPanelOpenElement && !availableForSale && css.disabled)}
      key={`option_${index}`}
      active={isActive}
      value={size}
      state={variantState()}
      stockState={stockState()}
      onClick={handleOnClick}
    />
  )
}

function ProductVariantsSelector({
  className,
  variants,
  activeVariant,
  withMobileSelect = false,
  stockAlertEnabled = false,
  onChange,
  isInAddToCartPopin,
}: ProductVariantsSelectorProps) {
  const t = useTranslate()
  const isFixedPanelOpenElement = useIsFixedPanelOpen()[0]

  const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const index = Number(e.target.value)
    onChange?.(index)
  }

  return (
    <div className={clsx(css.wrapper)}>
      <div
        className={clsx(
          css.ProductVariantsSelector,
          className,
          isInAddToCartPopin && sprinkles({ justifyContent: "center" }),
          css.buttonsState[withMobileSelect ? "hiddenMobile" : "default"]
        )}
      >
        {variants?.map((variant, index) => (
          <VariantButton
            key={index}
            variant={variant}
            index={index}
            activeVariant={activeVariant}
            stockAlertEnabled={stockAlertEnabled}
            isFixedPanelOpenElement={isFixedPanelOpenElement}
            onChange={onChange}
          />
        ))}
      </div>
      <Select
        id="quantity"
        className={clsx(
          css.select,
          css.selectState[withMobileSelect ? "mobileSelect" : "noSelect"],
          sprinkles({ fontSize: 12, letterSpacing: 0.01, lineHeight: 1 })
        )}
        addRequiredIndicatorOnLabel={true}
        theme="black"
        name="quantity"
        aria-label={t("general_variants")}
        onChange={handleOnChange}
        options={variants.map((variant, index) => ({
          label: variant.size ?? "",
          value: index,
        }))}
      />
    </div>
  )
}

export { type ProductVariantsSelectorProps }
export default ProductVariantsSelector
