"use client"

import { useRef } from "react"
import clsx from "clsx"

import { Spinner } from "@unlikelystudio/react-abstract-components"

import { useGetShopTheLookItems } from "~/hooks/useGetShopTheLookItems"
import PanelHeader from "~/components/ui/Panels/PanelHeader"
import type { ShopTheLook } from "~/components/ui/Panels/ShopTheLook/_data/serializer"
import ShopThelookContent from "~/components/ui/Panels/ShopTheLook/components/ShopTheLookContent"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { Panel } from "~/managers/PanelManager"

import * as css from "./styles.css"

type ShopTheLookPanelProps = (
  | {
      refs: string[]
      handles?: never
    }
  | { handles: string[]; refs?: never }
) & {
  items?: ShopTheLook
}

function ShopTheLookPanel({ items, ...params }: ShopTheLookPanelProps) {
  const panelRef = useRef<HTMLDivElement>(null)
  const t = useTranslate()

  const { data: fetchedItems, isLoading } = useGetShopTheLookItems(params, {
    enabled: !items,
  })

  const products = items ?? fetchedItems

  return (
    <Panel className={clsx(css.wrapper)} zIndex={60} clickOutsideRef={panelRef}>
      <div className={clsx(css.ShopTheLookPanel)} ref={panelRef}>
        <PanelHeader className={clsx(css.header)} title={t("shop_the_look")} />
        {isLoading ? (
          <Spinner className={clsx(css.spinner)} />
        ) : (
          <ShopThelookContent className={clsx(css.content)} items={products} />
        )}
      </div>
    </Panel>
  )
}

export default ShopTheLookPanel
export { type ShopTheLookPanelProps }
