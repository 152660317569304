import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import type { ShopTheLookPanelProps } from "~/components/ui/Panels/ShopTheLook"
import ShopTheLookItem from "~/components/ui/Panels/ShopTheLook/components/ShopTheLookItem"

import * as css from "./styles.css"

export type ShopThelookContentProps = PropsWithClassName<{
  items: ShopTheLookPanelProps["items"]
}>

function ShopThelookContent({ className, items }: ShopThelookContentProps) {
  return (
    items &&
    items?.length > 0 && (
      <div className={clsx(css.PanelContent, className)}>
        {items.map((item, index) => (
          <ShopTheLookItem key={`shop_the_look_item_${index}`} className={clsx(css.item)} item={item} />
        ))}
      </div>
    )
  )
}

export default ShopThelookContent
