import type { ComponentProps } from "react"
import clsx from "clsx"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import Icon from "~/components/abstracts/Icon"

import * as css from "./styles.css"

type VariantSelectorProps = PropsWithClassName<
  {
    active: boolean
    value?: Nullish<string>
    stockState: keyof typeof css.stock
    state: keyof typeof css.state
  } & Omit<ComponentProps<"button">, "value">
>

export function VariantSelector({ className, active, value, stockState, state, ...rest }: VariantSelectorProps) {
  return (
    value && (
      <button
        className={clsx(
          className,
          css.variantSelector({ size: value.length <= 3 ? "small" : "large" }),
          active === true ? css.active["active"] : css.active["default"],
          css.stock[stockState],
          css.state[active && state === "default" ? "active" : state]
        )}
        aria-label={value}
        {...rest}
      >
        {state === "stock_alert" && (
          <Icon name="Bell" direction="right" theme="grey-tundora" className={clsx(css.stockAlertIcon)} />
        )}
        {value}
      </button>
    )
  )
}
