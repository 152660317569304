import {
  getSfProductByHandle,
  type IGetShopifyProductByHandleParams,
} from "~/lib/shopify/queries/get-sf-product-by-handle"

export type IGetShopifyProductsByHandleParams = {
  handles: IGetShopifyProductByHandleParams["handle"][]
} & Omit<IGetShopifyProductByHandleParams, "handle">

export async function getSfProductsByHandle({ handles, ...params }: IGetShopifyProductsByHandleParams) {
  return Promise.all(
    handles.map((handle) => getSfProductByHandle({ handle, ...params } as IGetShopifyProductByHandleParams))
  )
}
