import { useEffect, useRef, useState, type DependencyList } from "react"
import { useInView } from "react-intersection-observer"

import { useIsomorphicLayoutEffect } from "@unlikelystudio/react-hooks"

export const useIntervalWithVisibilityCheck = (callback: () => void, delay: number | null, ...deps: DependencyList) => {
  const savedCallback = useRef(callback)
  const originalDelay = useRef(delay)
  const currentIntervalId = useRef<NodeJS.Timeout | null>(null)
  const { ref, inView } = useInView()

  const [isTabHidden, setIsTabHidden] = useState(false)

  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const checkedIntervalID = currentIntervalId.current ? currentIntervalId.current : undefined

    if (isTabHidden || delay !== originalDelay.current) {
      clearInterval(checkedIntervalID)
      return
    }

    if (!delay && delay !== 0) {
      return
    }

    if (!inView) {
      clearInterval(checkedIntervalID)
      return
    }

    currentIntervalId.current = setInterval(() => savedCallback.current(), delay)

    return () => {
      clearInterval(checkedIntervalID)
    }
  }, [delay, isTabHidden, inView, ...deps])

  useEffect(() => {
    const setTabHidden = () => {
      setIsTabHidden(document.hidden)
    }

    document.addEventListener("visibilitychange", setTabHidden)

    return () => {
      document.removeEventListener("visibilitychange", setTabHidden)
    }
  }, [])

  return { ref, inView }
}
