import type { TVariant } from "~/components/ui/ProductHeader/_data/types"

export function getDiscountFromPrices(
  compareAtPrice: NonNullable<TVariant["compareAtPrice"]>["amount"],
  price: NonNullable<TVariant["price"]>["amount"]
) {
  if (!compareAtPrice || !price) return null
  if (compareAtPrice <= price) return null
  const absoluteDiscount = compareAtPrice - price
  const discountPercentage = Math.trunc((absoluteDiscount / compareAtPrice) * 100)
  return discountPercentage ?? null
}
