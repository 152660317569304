import clsx from "clsx"

import type { SliderNavigationButtonProps } from "~/components/ui/SliderNavigation/types/button"
import Icon from "~/components/abstracts/Icon"

import { type Sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

type ArrowButtonProps = SliderNavigationButtonProps & { theme?: Sprinkles["color"] }

function ArrowButton({ onClick, className, ariaLabel, direction, theme = "black" }: ArrowButtonProps) {
  return (
    <button
      className={clsx(css.button, className)}
      onClick={() => {
        onClick?.()
      }}
      aria-label={ariaLabel}
    >
      <Icon theme={theme} className={clsx(css.icon)} name="Arrow" {...(direction ? { direction } : {})} />
    </button>
  )
}

export default ArrowButton
