"use client"

import { useEffect, useRef, type ComponentProps } from "react"
import dynamic from "next/dynamic"
import clsx from "clsx"

import { useIsHover } from "@unlikelystudio/react-hooks"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import { STOCK_LOW } from "~/lib/shopify/constants"
import { useGetStockByMarket, useQueryStockParams } from "~/hooks/useGetStockByMarket"
import InlineCta from "~/components/ui/InlineCta"
import { type SizeGuidePanelProps } from "~/components/ui/Panels/SizeGuide"
import type { TProductHeader } from "~/components/ui/ProductHeader/_data/serializer"
import type { TVariant } from "~/components/ui/ProductHeader/_data/types"
import ProductVariantsSelector from "~/components/ui/ProductHeader/components/ProductDetails/components/ProductVariantsSelector"
import type { TSizeGuideSlice } from "~/components/slices/SizeGuideSlice"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { usePanel } from "~/managers/PanelManager"
import type { VariantsRowWithVariantSwitchProps } from "~/app/[locale]/products/[slug]/(components)/VariantsRowWithVariantSwitch"
import { useActiveVariant } from "~/app/[locale]/products/[slug]/(hooks)/useVariantSwitch"

import { sprinkles } from "~/styles/sprinkles.css"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

const SizeGuidePanel = dynamic(() => import("~/components/ui/Panels/SizeGuide"), { ssr: false })

export type VariantsRowProps = PropsWithClassName<{
  variants: TProductHeader["serializedProduct"]["variants"]
  defaultVariant: TVariant | null
  onChange?: ComponentProps<typeof ProductVariantsSelector>["onChange"]
  sizeGuideDisabled?: boolean
  stockAlertEnabled?: boolean
  withMobileSelect?: boolean
  productType: string | null
  label?: string
  sizeGuide?: Nullish<TSizeGuideSlice>
}>

type StockLowProps = {
  selectedVariant: TVariant | undefined
  display: Record<string, string>
}

function StockLow({ selectedVariant, display }: StockLowProps) {
  const t = useTranslate()
  const params = useQueryStockParams()
  const { data } = useGetStockByMarket(selectedVariant?.sku ? [selectedVariant?.sku] : [], params)
  const processedStock = data?.[0] ?? undefined
  return (
    ((processedStock !== undefined && processedStock <= STOCK_LOW) || selectedVariant?.isStockLow) && (
      <div className={clsx(css.lowStockAlert, sprinkles({ display }))}>{t("product_stock_low")}</div>
    )
  )
}

function VariantsRow({
  className,
  variants,
  defaultVariant,
  withMobileSelect = false,
  productType,
  stockAlertEnabled,
  sizeGuideDisabled,
  sizeGuide,
  isInAddToCartPopin = false,
  label,
  onChange: changeFunction,
}: VariantsRowProps & Pick<VariantsRowWithVariantSwitchProps, "isInAddToCartPopin">) {
  const { add } = usePanel()
  const t = useTranslate()

  const [selectedVariant] = useActiveVariant()
  const activeVariant = selectedVariant ?? defaultVariant

  const onChange = (key: number | undefined) => {
    if (key !== undefined) {
      changeFunction?.(key)
    }
  }

  const openStylePanel = ({ productType, content }: SizeGuidePanelProps) => {
    add(<SizeGuidePanel productType={productType} content={content} />)
  }
  const hideSizeguideCta = !productType || sizeGuideDisabled

  const [isHover, callbacks] = useIsHover()
  const hoverTimes = useRef<number>(0)
  useEffect(() => {
    hoverTimes.current += 1
  }, [isHover])

  return (
    <div className={clsx(css.VariantsRow, className)}>
      {isInAddToCartPopin ? (
        <p
          className={clsx(
            sprinkles({ textAlign: "center", marginBottom: 20 }),
            text({
              design: "body-neue-14",
              color: "grey-tundora",
            })
          )}
        >
          {label ?? t("product_details_size")}
        </p>
      ) : (
        <p
          className={clsx(
            sprinkles({ marginBottom: 6 }),
            text({
              design: "neue-13-12",
              color: "grey-tundora",
            })
          )}
        >
          {label ?? t("product_details_size")}
        </p>
      )}
      <div className={clsx(css.ctasContainer, isInAddToCartPopin && css.ctasContainerInAddToCartPopin)}>
        {variants && (
          <ProductVariantsSelector
            isInAddToCartPopin={isInAddToCartPopin}
            withMobileSelect={withMobileSelect}
            variants={variants}
            stockAlertEnabled={stockAlertEnabled}
            activeVariant={activeVariant}
            onChange={onChange}
          />
        )}
        <div
          className={clsx(
            css.sizeGuideRow,
            css.sizeGuideRowCta[hideSizeguideCta ? "noCta" : "default"],
            isInAddToCartPopin && css.sizeGuideInAddToCartPopin
          )}
        >
          {activeVariant && <StockLow selectedVariant={activeVariant} display={{ mobile: "block", desktop: "none" }} />}
          {!sizeGuideDisabled && (
            <InlineCta
              onClick={() => openStylePanel({ productType, content: sizeGuide })}
              {...callbacks}
              className={clsx(css.sizeguideTrigger, isInAddToCartPopin && css.sizeGuideTriggerExtraMargin)}
              withDefaultLine
            >
              {t("size_guide")}
            </InlineCta>
          )}
        </div>
      </div>
      {activeVariant && <StockLow selectedVariant={activeVariant} display={{ mobile: "none", desktop: "block" }} />}
    </div>
  )
}

export default VariantsRow
