import { useEffect } from "react"
import { useQuery, type UseQueryOptions } from "@tanstack/react-query"

import useLocale from "~/hooks/useLocale"
import { serializeShopTheLook, type ShopTheLook } from "~/components/ui/Panels/ShopTheLook/_data/serializer"

type QueryOptions = {
  throwError?: boolean
  onError?(error: Error): void
  onSuccess?(data: ShopTheLook): void
} & Omit<UseQueryOptions<ShopTheLook, Error, ShopTheLook>, "queryFn" | "onError">

type Params =
  | {
      refs: string[]
      handles?: never
    }
  | {
      refs?: never
      handles: string[]
    }

export function useGetShopTheLookItems({ refs, handles }: Params, queryOptions?: QueryOptions) {
  const locale = useLocale()
  const { queryKey = [refs, handles], ...options } = queryOptions ?? {}

  const response = useQuery<ShopTheLook, Error, ShopTheLook>({
    queryKey: ["fetchShopTheLookProducts", ...queryKey],
    queryFn: async () => {
      return (await serializeShopTheLook({ refs, handles, locale })) ?? []
    },
    ...options,
  })

  useEffect(() => {
    switch (response.status) {
      case "error":
        options?.onError?.(response.error)
        break
      case "success":
        options?.onSuccess?.(response.data)
        break
      default:
        break
    }
  }, [response.status])

  return response
}
