import type { UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import type { InferReturn } from "~/@types/generics"
import { productMetafields } from "~/lib/shopify/constants"
import { getSfProducts } from "~/lib/shopify/queries/get-sf-products"
import { getSfProductsByHandle } from "~/lib/shopify/queries/get-sf-products-by-handle"
import { getLocalizedMetafields } from "~/lib/shopify/utils/get-localized-metafields"
import { sfTagsArrayToQuery } from "~/lib/shopify/utils/sf-tags-array-to-query"
import { serializeProductHeaderSP } from "~/components/ui/ProductHeader/_data/serializer"
import { unlikelyProductToTrackingData } from "~/providers/GTMTrackingProvider/utils/unlikely-product-to-tracking-data"
import { objectValues } from "~/utils/object-values"

type Params = { locale: string; refs?: string[]; handles?: string[] }

export async function serializeShopTheLook({ refs, handles, locale }: Params) {
  const getRefsProducts = async (refs: string[]) => {
    return (
      await getSfProducts({
        locale: locale,
        metafieldKeys: objectValues(getLocalizedMetafields(productMetafields, locale)),
        query: sfTagsArrayToQuery(refs),
        collectionsFirst: 10,
        includeTags: true,
      })
    ).products.filter(Boolean)
  }

  const getHandlesProducts = async (handles: string[]) =>
    (await getSfProductsByHandle({ handles, locale })).map((product) => product.productByHandle).filter(Boolean)

  const products = refs
    ? sortByRefs(refs, await getRefsProducts(refs))
    : handles
    ? sortByHandles(handles, await getHandlesProducts(handles))
    : []

  const serializedProducts =
    (await Promise.all(
      products.map(async (product) => ({
        ...serializeProductHeaderSP(product, locale),
        trackingData: unlikelyProductToTrackingData(product, locale),
      }))
    )) ?? []

  return serializedProducts
}

export type ShopTheLook = InferReturn<typeof serializeShopTheLook>

function sortByRefs(refs: string[], products: UnlikelyProduct[]) {
  return refs.map((ref) => products.find((product) => product.tags.includes(ref))).filter(Boolean)
}

function sortByHandles(handles: string[], products: UnlikelyProduct[]) {
  return handles.map((handle) => products.find((product) => product.handle === handle)).filter(Boolean)
}
