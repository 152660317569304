"use client"

import { Fragment, useEffect, type PropsWithChildren } from "react"
import { usePathname } from "next/navigation"

import type { SbPagesComponent } from "~/lib/storyblok/constants"
import { usePageHistory } from "~/managers/PageHistoryManager"

export function PageWrapper({
  pageType,
  children,
}: PropsWithChildren<{ pageType: SbPagesComponent | "product_page" }>) {
  const { history, update } = usePageHistory()
  const pathname = usePathname()
  const previousPathname = history?.[history?.length - 1]?.pathname
  const splitedPathname = pathname.split("/")
  const rootFolder = splitedPathname?.[1] ?? ""

  useEffect(() => {
    if (pageType && pathname !== previousPathname) {
      update({ type: pageType, pathname })
    }

    if (["faq", "legals", "contact"].includes(rootFolder)) {
      window.scrollTo({ top: 0 })
    }
  }, [])

  return <Fragment>{children}</Fragment>
}
