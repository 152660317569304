"use client"

import Slider, { type SliderProps } from "@unlikelystudio/react-slider"

type ProductIconicSliderClientProps = {
  children: SliderProps["children"]
  isInfinite: boolean
}

export default function ProductIconicSliderClient({ children, isInfinite }: ProductIconicSliderClientProps) {
  return (
    <Slider maxSlideIndexChange={1} snap infinite={isInfinite}>
      {children}
    </Slider>
  )
}
