import type { ComponentProps } from "react"
import { atom, useAtomValue, useSetAtom } from "jotai"

import type { PageWrapper } from "~/components/globals/PageWrapper"

export type PageHistoryEntry = { type: ComponentProps<typeof PageWrapper>["pageType"]; pathname: string }

const pageHistoryAtom = atom<PageHistoryEntry[]>([])

const pageHistorySetterAtom = atom(null, (get, set, newEntry: PageHistoryEntry) => {
  const currentState = get(pageHistoryAtom) ?? []
  return set(pageHistoryAtom, [...currentState, newEntry])
})

export function usePageHistory() {
  const history = useAtomValue(pageHistoryAtom)
  const update = useSetAtom(pageHistorySetterAtom)

  return { history, update }
}
