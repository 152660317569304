"use client"

import { useEffect } from "react"
import { usePathname } from "next/navigation"

import { PageViewAnalytics } from "@unlikelystudio/react-ecommerce-hooks"

import type { PageName } from "~/providers/GTMTrackingProvider/constants"
import { useTracking } from "~/providers/GTMTrackingProvider/hooks/use-tracking"

type ClientPageViewProps = {
  pageName: PageName
}

export function ClientPageView({ pageName }: ClientPageViewProps) {
  const { sendEvent } = useTracking()
  const pathname = usePathname()

  useEffect(() => {
    sendEvent("page_view", {
      pageTitle: pageName,
      pagePath: pathname,
    })
  }, [])

  return <PageViewAnalytics pathname={pathname} template="product" />
}
