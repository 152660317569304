"use client"

import { useMemo, useState } from "react"
import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import { formatCheckoutItems } from "~/lib/shopify/utils/format-checkout-items"
import { AddToCartCta } from "~/components/ui/AddToCartCta"
import { Image } from "~/components/ui/Image"
import { Link } from "~/components/ui/Link"
import type { ShopTheLookPanelProps } from "~/components/ui/Panels/ShopTheLook"
import ProductDetailsPrice from "~/components/ui/ProductHeader/components/ProductDetails/components/ProductDetailsPrice"
import VariantsRow from "~/components/ui/ProductHeader/components/ProductDetails/components/VariantsRow"
import { getProductTrackingDataWithCollections } from "~/providers/GTMTrackingProvider/utils/get-product-tracking-data-with-collections"
import { variantToTrackingData } from "~/providers/GTMTrackingProvider/utils/variant-to-tracking-data"
import { useCollectionTree } from "~/managers/CollectionsTreeManager/hooks"
import { usePanel } from "~/managers/PanelManager"
import numberAsBoolean from "~/utils/number-as-boolean"

import { sprinkles } from "~/styles/sprinkles.css"
import { breakpoints } from "~/styles/variables/breakpoints"

import * as css from "./styles.css"

type ShopTheLookItemProps = PropsWithClassName<{ item: NonNullable<ShopTheLookPanelProps["items"]>[number] }>

const IMAGE_SIZES = [{ breakpoint: breakpoints.lg, ratio: 180 / 1440 }, { ratio: 4 / 12 }]

function ShopTheLookClientItem({ className, item }: ShopTheLookItemProps) {
  const { title, images, variants, defaultVariant, productType, link, trackingData, collectionsHandles } = item

  const { removeCurrent } = usePanel()

  const defaultVariantIndex = variants.findIndex((variant) => variant.id === defaultVariant?.id)
  const [activeVariantIndex, setActiveVariant] = useState<number | undefined>(defaultVariantIndex)
  const [collectionTree] = useCollectionTree()
  const activeVariant =
    typeof activeVariantIndex === "number" && numberAsBoolean(activeVariantIndex)
      ? variants?.[activeVariantIndex]
      : null

  const processedTrackingData = {
    ...getProductTrackingDataWithCollections(trackingData, collectionsHandles, collectionTree),
    ...(activeVariant ? variantToTrackingData(activeVariant) ?? {} : {}),
  }

  const checkoutItems = useMemo(() => {
    if (!activeVariant) return []
    return formatCheckoutItems({ variant: activeVariant, trackingData: processedTrackingData })
  }, [activeVariantIndex])

  const isOutOfStock = !activeVariant?.availableForSale
  return (
    <div className={clsx(css.ShopTheLookItem, className)}>
      <div className={clsx(css.row)}>
        {images?.[0] && (
          <Link className={clsx(css.image)} {...link} onClick={removeCurrent}>
            <Image asPlaceholder sizesFromBreakpoints={IMAGE_SIZES} {...images?.[0]} ratio="180_253" />
          </Link>
        )}
        <div className={clsx(css.content)}>
          <span className={clsx(css.title)}>{title}</span>
          {defaultVariant && <ProductDetailsPrice className={clsx(css.price)} {...defaultVariant} />}
          <VariantsRow
            className={clsx(css.variants)}
            withMobileSelect
            variants={variants}
            defaultVariant={defaultVariant}
            sizeGuideDisabled
            productType={productType}
            onChange={setActiveVariant}
          />
          <div className={clsx(css.cta, sprinkles({ display: { mobile: "none", desktop: "block" } }))}>
            <AddToCartCta
              fill
              isOutOfStock={isOutOfStock}
              activeVariants={activeVariant}
              checkoutItems={checkoutItems}
            />
          </div>
        </div>
      </div>
      <div className={clsx(css.cta, sprinkles({ display: { mobile: "block", desktop: "none" } }))}>
        <AddToCartCta fill isOutOfStock={isOutOfStock} activeVariants={activeVariant} checkoutItems={checkoutItems} />
      </div>
    </div>
  )
}

export default ShopTheLookClientItem
