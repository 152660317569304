"use client"

import type { CSSProperties } from "react"
import clsx from "clsx"
import { AnimatePresence, m } from "framer-motion"

import { useIsHover } from "@unlikelystudio/react-hooks"

import type { PropsWithClassName } from "~/@types/generics"
import { isDarel } from "~/lib/brand"
import serializePrice from "~/data/price/serializer"
import useLocale from "~/hooks/useLocale"
import { FillImage } from "~/components/ui/FillImage"
import { Image, type ImageProps } from "~/components/ui/Image"
import { Link } from "~/components/ui/Link"
import type { TProductCard } from "~/components/ui/ProductCard/_data/serializer"
import { Ratio } from "~/components/abstracts/Ratio"

import { sprinkles } from "~/styles/sprinkles.css"
import { bezierCurves } from "~/styles/variables/bezier-curves"
import { breakpoints } from "~/styles/variables/breakpoints"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

export type ProductCardProps = PropsWithClassName<
  TProductCard & {
    cardImageProps?: Partial<ImageProps>
    isLoading?: boolean
    style?: CSSProperties
  }
>

export const PRODUCT_CARD_IMAGE_SIZES = {
  DEFAULT: [{ breakpoint: breakpoints.lg, ratio: 1 / 3 }, { ratio: 1 }],
}

export function MockProductCard({ className }: PropsWithClassName<{}>) {
  return (
    <article className={clsx(css.Mock, className)}>
      <div className={clsx(css.mediasContainer)}>
        {<Ratio className={clsx(css.image)} ratio={sprinkles({ ratio: "74_110" })} />}
      </div>
      <div className={clsx(css.textualContent)}>
        <span className={clsx(css.text, text({ design: "body-neue-12", color: "black" }))}>&nbsp;</span>
        <b className={clsx(css.price, text({ design: "body-neue-12", fontWeight: "medium", color: "black" }))}>
          &nbsp;
        </b>
      </div>
    </article>
  )
}

function ProductCard({
  className,
  title,
  price,
  image,
  hoverImage,
  link,
  isLoading = false,
  cardImageProps,
  style,
}: ProductCardProps) {
  const [isHover, callbacks] = useIsHover()
  const locale = useLocale()

  if (isLoading) return <MockProductCard className={className} />
  if (!image) return null

  const serializedPrice =
    price?.currencyCode && price?.amount && serializePrice(locale, price?.currencyCode, price?.amount)

  const Content = (className?: string) => (
    <article className={className} {...callbacks}>
      <div className={clsx(css.mediasContainer)}>
        <Image imageClassName={css.image} {...image} {...cardImageProps} asPlaceholder ratio={"74_110"} />
        <AnimatePresence>
          {hoverImage && isHover && (
            <m.div
              className={clsx(css.hoverImage)}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { duration: 0.25, ease: bezierCurves["easeOutQuart"] },
              }}
              exit={{
                opacity: 0,
                transition: { duration: 0.25, ease: bezierCurves["easeOutQuart"] },
              }}
            >
              <FillImage {...hoverImage} {...cardImageProps} />
            </m.div>
          )}
        </AnimatePresence>
      </div>
      <div className={clsx(css.textualContent)}>
        <span className={clsx(text({ design: "body-neue-12", color: "black", textTransform: "uppercase" }))}>
          {title}
        </span>
        <b
          className={clsx(
            css.price,
            text({ design: "body-neue-12", fontWeight: "medium", color: isDarel ? "black" : "grey-tundora/70" })
          )}
        >
          {serializedPrice}
        </b>
      </div>
    </article>
  )

  return link ? (
    <Link className={clsx(css.ProductCard, className)} style={style} {...link}>
      {Content()}
    </Link>
  ) : (
    Content(clsx(css.ProductCard, className))
  )
}

export default ProductCard
