// import { useGetCart } from "~/hooks/useGetCart"

// import { useParams } from "next/navigation"

import { useGetCart } from "~/hooks/useGetCart"
import { useGetStockByMarket, useQueryStockParams } from "~/hooks/useGetStockByMarket"
import type { TVariant } from "~/components/ui/ProductHeader/_data/types"

type CustomVariant = Pick<TVariant, "id" | "price" | "sku" | "vpPrice">

export function useHasReachMaxAvailableQuantity(variants: CustomVariant[]): boolean {
  const params = useQueryStockParams()
  const skus = variants?.map((variant) => variant?.sku)?.filter(Boolean) ?? null

  const { data } = useGetStockByMarket(skus, params)
  const isOutOfStock = data?.some((item) => item === 0) ?? false

  const { cartPayload } = useGetCart()

  let hasReactMaxQuantities = false

  variants?.forEach((variant, index) => {
    const currentLineItem = cartPayload?.lines?.find((lineItem) => lineItem?.merchandise.id === variant?.id)

    if (hasReactMaxQuantities) {
      return
    }

    const stockFromAdminApi = data?.[index] ?? null

    hasReactMaxQuantities =
      (currentLineItem?.merchandise &&
        currentLineItem?.quantity ===
          (stockFromAdminApi ? stockFromAdminApi : currentLineItem?.merchandise?.quantityAvailable)) ??
      false
  })

  return isOutOfStock || hasReactMaxQuantities
}
