import { useQuery, type UseQueryOptions } from "@tanstack/react-query"

import type { Nullish } from "~/@types/generics"
import { SHOPIFY_ADMIN_STOCK_PARAMS } from "~/lib/api/constants"
import { requestNextApiEndpoint } from "~/lib/api/utils/request-endpoint"
import { isDarel } from "~/lib/brand"
import useMarket from "~/hooks/useMarket"

type Return = number[] | null

export function useQueryStockParams() {
  const market = useMarket()

  return {
    enabled: isDarel && market !== "France",
  }
}

type QueryOptions = {
  throwError?: boolean
  onError?(error: Error): void
  onSuccess?(data: Return): void
} & Omit<UseQueryOptions<Return, Error, Return>, "queryFn" | "onError">
export function useGetStockByMarket(skus: Nullish<string[]>, queryOptions?: QueryOptions) {
  const { ...options } = queryOptions ?? {}

  return useQuery<Return, Error, Return>({
    queryKey: ["get_stock", skus],
    queryFn: async () => {
      if (!skus) return null

      return (await Promise.all(
        skus.map(async (sku) =>
          requestNextApiEndpoint(SHOPIFY_ADMIN_STOCK_PARAMS, {
            queryParams: { sku },
          })
        )
      )) as number[]
    },
    ...options,
  })
}
