import { useEffect } from "react"
import { atom, useAtom } from "jotai"
import { useQueryState } from "next-usequerystate"

import { getVariantMinPrice } from "~/lib/shopify/utils/get-variant-min-price"
import { getReferenceIdFromGID } from "~/lib/shopify/utils/id"
import type { TVariant } from "~/components/ui/ProductHeader/_data/types"

type UseVariantSwitchArgs = {
  variants: TVariant[]
  defaultVariant: TVariant | null
}

const ActiveVariant = atom<TVariant | null>(null)

export function useActiveVariant() {
  return useAtom(ActiveVariant)
}

export function useVariantSwitch({ variants, defaultVariant }: UseVariantSwitchArgs) {
  const [queryVariantId, setQueryVariantId] = useQueryState("variant", { shallow: true })
  const [activeVariant, setActiveVariant] = useActiveVariant()

  const queryActiveVariant = variants?.find(
    (variant) => getReferenceIdFromGID(variant.id)?.toString() === queryVariantId
  )

  useEffect(() => {
    if (queryActiveVariant && queryActiveVariant?.id !== activeVariant?.id) {
      setActiveVariant(queryActiveVariant)
    }
  }, [queryActiveVariant])

  const priceVariant = activeVariant ?? defaultVariant ?? getVariantMinPrice(variants)

  const onChange = (index: number | undefined) => {
    const activeVariant = index === undefined ? defaultVariant : variants?.[index] ?? defaultVariant
    setActiveVariant(activeVariant)
    setQueryVariantId(getReferenceIdFromGID(activeVariant?.id)?.toString() ?? null)
  }

  return {
    activeVariant: activeVariant ?? defaultVariant,
    priceVariant,
    onChange,
  }
}
